import { ring } from 'ldrs';
import { Label } from "@radix-ui/react-label";

ring.register();

export const LoadingSpinner = () => (
  <div className="fixed inset-0 flex flex-col items-center justify-center gap-4 bg-background z-50">

    <Label className="text-base">Cargando</Label>

    <div className="rounded-full h-12 w-12 bg-primary/10 flex justify-center items-center">
      <l-ring
        size="35"
        speed="1.2"
        color="#1f62ff"
      />
    </div>

  </div>
);