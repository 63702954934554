import { lazy, Suspense, useEffect } from "react";
import { PrivateRoute, Toaster } from "./components";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LoadingSpinner } from "./components/loading-spinner.tsx";
import useNavigatorOnline from "./hooks/use-navigator-online.ts";
import { retryOfflineRequests } from "./utilities/offline-db.ts";

const HomeLayout = lazy(() => import("./pages/home/layout"));
const PointOfSaleLayout = lazy(() => import("./pages/point-of-sale/layout"));
const OthersLayout = lazy(() => import("./pages/[others]/layout.tsx"));

const HomePage = lazy(() => import("./pages/home/page"));
const LoginPage = lazy(() => import("./pages/login/page"));
const OpenCashierPage = lazy(() => import("./pages/home/pages/cashiers/page"));
const ProductsPage = lazy(() => import("./pages/point-of-sale/pages/products/page"));
const StoresPage = lazy(() => import("./pages/home/pages/stores/page"));
const CloseCashierPage = lazy(() => import("./pages/[others]/pages/close-cashier/page"));
const SalesPage = lazy(() => import("./pages/[others]/pages/sales/page"));
const DashboardPage = lazy(() => import("./pages/[others]/pages/dashboard/page"));
const PointOfSalePage = lazy(() => import("./pages/point-of-sale/page"));




function App() {
  const { isOnline } = useNavigatorOnline();

  useEffect(() => {
    if (isOnline) {
      // Reintentar cada 10 segundos
      const interval = setInterval(() => {
        retryOfflineRequests();
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [isOnline]);


  return (
    <>
      <Toaster
        toastOptions={ {
          classNames: {
            default: 'bg-white border-input',
            error: 'text-destructive',
            success: 'text-green-400',
            warning: 'text-yellow-400',
            info: 'text-primary',
          },
        } }
        position="top-right"
        expand
      />
      <BrowserRouter>
        <Suspense fallback={ <LoadingSpinner/> }>
          <Routes>
            <Route path="/login" element={ <LoginPage/> }/>
            <Route path="/" element={ <PrivateRoute><HomeLayout/></PrivateRoute> }>
              <Route index element={ <HomePage/> }/>
              <Route path="stores" element={ <StoresPage/> }/>
              <Route path="cashiers/:id/open" element={ <OpenCashierPage/> }/>
            </Route>
            <Route path="/point-of-sale" element={ <PrivateRoute><PointOfSaleLayout/></PrivateRoute> }>
              <Route index element={ <PointOfSalePage/> }/>
              <Route path="products" element={ <ProductsPage/> }/>
            </Route>
            <Route element={ <PrivateRoute><OthersLayout/></PrivateRoute> }>
              <Route path="/cashiers/:id/close" element={ <CloseCashierPage/> }/>
              <Route path="/sales" element={ <SalesPage/> }/>
              <Route path="/dashboard" element={ <DashboardPage/> }/>
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;