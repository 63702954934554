import PouchDB from 'pouchdb-browser';

interface OfflinePost {
    _id: string;
    url: string;
    headers: Record<string, string>; //  Guardar headers correctamente
    body: any;
}

const db = new PouchDB<OfflinePost>('offline-posts');

// Función para reenviar solicitudes cuando haya conexión
export const retryOfflineRequests = async () => {
    try {
        const allDocs = await db.allDocs({ include_docs: true });

        for (const row of allDocs.rows) {
            const post = row.doc;

            if (!post || !post.url) {
                console.warn('[OfflineDB] Documento sin URL, saltando...', post);
                continue;
            }

            // Restaurar TODOS los headers correctamente sin excluir ninguno
            const headers = new Headers(post.headers);

            const response = await fetch(post.url, {
                method: 'POST',
                headers: headers, // Enviar headers originales restaurados
                body: JSON.stringify(post.body)
            });

            if (response.ok) {
                await db.remove(post);
            } else {
                console.error("[OfflineDB] Error al reenviar POST: ${post.url}");
            }
        }
    } catch (error) {
        console.error("[OfflineDB] No se pudo reenviar las solicitudes offline, error");
    }
};

// Escuchar cuando vuelve la conexión y reintentar envíos
window.addEventListener('online', () => {
    console.log('[OfflineDB] Online: Reintentando envíos');
    retryOfflineRequests();
});
