import { create } from "zustand";
import { createJSONStorage, persist, devtools } from "zustand/middleware";
import { IdbStorage } from '@/store/idbStorage.ts';
import { FILTER_BY_BAR_CODE, FILTER_BY_NAME } from '@/utilities';
import { ReactNode } from "react";

export interface LayoutState {
  title: string;
  setTitle: (title: string) => void;
  showArrowLeft: boolean;
  setShowArrowLeft: (arrowLeft: boolean) => void;
  showOrderBy: boolean;
  setShowOrderBy: (orderBy: boolean) => void;
  columns: number;
  setColumns: (columns: number) => void;
  showButton: boolean;
  setShowButton: (show: boolean) => void;
  loadMore: () => void;
  setLoadMore: (loadMore: () => void) => void;
  searchValue: string;
  setSearchValue: (searchValue: string) => void;
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
  filterType: number[];
  setFilterType: (updateFn: (prevFilters: number[]) => number[]) => void;
  inputBuffer: string;
  setInputBuffer: (inputBuffer: string) => void;
  headerChildren: (() => ReactNode) | undefined;
  setHeaderChildren: (headerChildren: (() => ReactNode) | undefined) => void;
  showHeaderBorder: boolean;
  setShowHeaderBorder: (showHeaderBorder: boolean) => void;
  isSheetOpen: boolean;
  setIsSheetOpen: (isSheetOpen: boolean) => void;
  showPriceAdjustmentPanel: boolean;
  setShowPriceAdjustmentPanel: (showPriceAdjustmentPanel: boolean) => void;
  showPointsPanel: boolean;
  setShowPointsPanel: (showPointsPanel: boolean) => void;
}

export const useLayoutStore = create<LayoutState>()(devtools(persist((set) => ({
  title: "Categorías",
  setTitle: (title) => set({ title }),
  showArrowLeft: false,
  setShowArrowLeft: (showArrowLeft) => set({ showArrowLeft }),
  showOrderBy: false,
  setShowOrderBy: (showOrderBy) => set({ showOrderBy }),
  columns: 1,
  setColumns: (columns) => set({ columns }),
  showButton: false,
  setShowButton: (showButton: boolean) => set({ showButton }),
  loadMore: () => {
  },
  setLoadMore: (loadMore) => set({ loadMore }),
  searchValue: "",
  setSearchValue: (searchValue: string) => set({ searchValue }),
  isLoading: false,
  setIsLoading: (isLoading) => set({ isLoading }),
  filterType: [FILTER_BY_NAME, FILTER_BY_BAR_CODE],
  setFilterType: (updateFn: number[] | ((prevFilters: number[]) => number[])) =>
    set((state) => ({
      filterType: typeof updateFn === "function" ? updateFn(state.filterType ?? [FILTER_BY_NAME, FILTER_BY_BAR_CODE]) : updateFn
    })),
  inputBuffer: "",
  setInputBuffer: (inputBuffer: string) => set({ inputBuffer }),
  headerChildren: undefined,
  setHeaderChildren: (headerChildren: (() => ReactNode) | undefined) => set({ headerChildren }),
  showHeaderBorder: false,
  setShowHeaderBorder: (showHeaderBorder: boolean) => set({ showHeaderBorder }),
  isSheetOpen: false,
  setIsSheetOpen: (isSheetOpen: boolean) => set({ isSheetOpen }),
  showPriceAdjustmentPanel: false,
  setShowPriceAdjustmentPanel: (showPriceAdjustmentPanel: boolean) => set({ showPriceAdjustmentPanel }),
  showPointsPanel: false,
  setShowPointsPanel: (showPointsPanel: boolean) => set({ showPointsPanel }),
}), {
  name: 'layout-store',
  storage: createJSONStorage(() => IdbStorage),
  partialize: (state) => (
    {
      title: state.title,
      searchValue: state.searchValue,
      showButton: state.showButton,
      isLoading: state.isLoading,
      filterType: state.filterType,
      inputBuffer: state.inputBuffer,
      headerChildren: state.headerChildren,
      showHeaderBorder: state.showHeaderBorder,
      isSheetOpen: state.isSheetOpen,
      showPriceAdjustmentPanel: state.showPriceAdjustmentPanel,
      showPointsPanel: state.showPointsPanel,
    }
  ),
})));