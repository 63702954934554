import Cookies from 'js-cookie';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { ICompanySettings, ICurrency, IStoreSettings } from '@/pages/home/models';
import { IAccount } from '@/pages/home/pages/stores/models/account.model.ts';
import { ICartItem } from '@/pages/point-of-sale/models/cart-item.model.ts';
import { ICustomer } from '@/models/customer.model.ts';
import { IBillType } from '@/pages/home/models/settings-api.model.ts';
import { PAYMENT_TYPE_CASH } from '@/utilities';
import { IdbStorage } from './idbStorage';
import createDeepMerge from '@fastify/deepmerge';
import { IStore } from '@/pages/home/pages/stores/models';
import { PaymentTypes } from '@/pages/point-of-sale/models/sale.model.ts';

const deepMerge = createDeepMerge({ all: true });

export interface SessionState {
  authToken: string | null;
  setAuthToken: (authToken: string | null) => void;
  storeId: number | undefined;
  setStoreId: (storeId: number | undefined) => void;
  companySettings: ICompanySettings | undefined;
  setCompanySettings: (companySettings: ICompanySettings | undefined) => void;
  storeSettings: IStoreSettings | undefined;
  setStoreSettings: (storeSettings: IStoreSettings | undefined) => void;
  lastUpdatedDate: number | undefined;
  setLastUpdatedDate: (lastUpdatedDate: number | undefined) => void;
  activeFilter: number;
  setActiveFilter: (activeFilter: number) => void;
  account: IAccount | undefined;
  setAccount: (account: IAccount | undefined) => void;
  currentItem: ICartItem | undefined;
  setCurrentItem: (currentItem: ICartItem | undefined) => void;
  currentCustomer: ICustomer | undefined;
  setCurrentCustomer: (customer: ICustomer | undefined) => void;
  companyCurrency: ICurrency | undefined;
  setCompanyCurrency: (companyCurrency: ICurrency | undefined) => void;
  selectedBillType: IBillType | undefined;
  setSelectedBillType: (selectedBillType: IBillType | undefined) => void;
  selectedPaymentType: PaymentTypes,
  setSelectedPaymentType: (selectedPaymentType: PaymentTypes) => void;
  store: IStore | undefined;
  setStore: (store: IStore | undefined) => void;
  logoBlobString: string | null;
  setLogoBlobString: (logoBlob: string | null) => void;
  isBackupDevice: boolean;
  setIsBackupDevice: (isBackupDevice: boolean) => void;
  isCurrentCustomerDefaultCustomer: boolean;
  setIsCurrentCustomerDefaultCustomer: (isCurrentCustomerDefaultCustomer: boolean) => void;
  customerAddInfo: ICustomer | null;
  setCustomerAddInfo: (customerAddInfo: ICustomer | null) => void;
}

export const useSessionStore = create<SessionState>()(devtools(
  persist((set) => ({
      authToken: Cookies.get('authToken') || null,
      setAuthToken: (authToken: string | null) => {
        if (authToken) {
          Cookies.set('authToken', authToken, { expires: 3 });
        } else {
          Cookies.remove('authToken');
        }
        set({ authToken });
      },
      storeId: parseInt(Cookies.get('storeId') ?? "0") || undefined,
      setStoreId: (storeId: number | undefined) => {
        if (storeId) {
          Cookies.set('storeId', storeId.toString(), { expires: 3 });
        } else {
          Cookies.remove('storeId');
        }
        set({ storeId });
      },
      companySettings: undefined,
      setCompanySettings: (companySettings: ICompanySettings | undefined) => set({ companySettings }),
      storeSettings: undefined,
      setStoreSettings: (storeSettings: IStoreSettings | undefined) => set({ storeSettings }),
      lastUpdatedDate: undefined,
      setLastUpdatedDate: (lastUpdatedDate: number | undefined) => set({ lastUpdatedDate }),
      activeFilter: 0,
      setActiveFilter: (activeFilter: number) => set({ activeFilter }),
      account: undefined,
      setAccount: (account: IAccount | undefined) => set({ account }),
      currentItem: undefined,
      setCurrentItem: (currentItem: ICartItem | undefined) => set({ currentItem }),
      currentCustomer: undefined,
      setCurrentCustomer: (currentCustomer) => set({ currentCustomer }),
      companyCurrency: undefined,
      setCompanyCurrency: (companyCurrency) => set({ companyCurrency }),
      selectedBillType: undefined,
      setSelectedBillType: (selectedBillType: IBillType | undefined) => set({ selectedBillType }),
      selectedPaymentType: PAYMENT_TYPE_CASH,
      setSelectedPaymentType: (selectedPaymentType: PaymentTypes) => set({ selectedPaymentType }),
      store: undefined,
      setStore: (store: IStore | undefined) => set({ store }),
      logoBlobString: null,
      setLogoBlobString: (logoBlobString: string | null) => set({ logoBlobString }),
      isBackupDevice: false,
      setIsBackupDevice: (isBackupDevice: boolean) => set({ isBackupDevice }),
      isCurrentCustomerDefaultCustomer: true,
      setIsCurrentCustomerDefaultCustomer: (isCurrentCustomerDefaultCustomer: boolean) => set({ isCurrentCustomerDefaultCustomer }),
      customerAddInfo: null,
      setCustomerAddInfo: (customerAddInfo: ICustomer | null) => set({ customerAddInfo }),
    }),
    {
      name: 'session-store',
      partialize: (state) => (
        {
          storeId: state.storeId,
          companySettings: state.companySettings,
          storeSettings: state.storeSettings,
          lastUpdatedDate: state.lastUpdatedDate,
          account: state.account,
          currentItem: state.currentItem,
          currentCustomer: state.currentCustomer,
          companyCurrency: state.companyCurrency,
          selectedBillType: state.selectedBillType,
          selectedPaymentType: state.selectedPaymentType,
          store: state.store,
          logoBlobString: state.logoBlobString,
          isBackupDevice: state.isBackupDevice,
          isCurrentCustomerDefaultCustomer: state.isCurrentCustomerDefaultCustomer,
          customerAddInfo: state.customerAddInfo,
        }),
      storage: createJSONStorage(() => IdbStorage),
      merge: (persistedState, currentState) => {
        if (!persistedState) return currentState;
        return deepMerge(currentState, persistedState) as never;
      }
    }
  )
));
