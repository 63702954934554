export const ORDER_BY_NONE = 0;
export const ORDER_BY_EXPIRATION = 1;
export const FILTER_BY_NAME = 1;
export const FILTER_BY_BAR_CODE = 2;
export const FILTER_BY_LAB = 3;
export const FILTER_BY_ACTIVE_INGREDIENT = 4;
export const FILTER_BY_PATHOLOGY = 5;
export const DISCOUNT_BY_PRICE = "1";
export const DISCOUNT_BY_PERCENTAGE = "2";
export const DISCOUNT_BY_AMOUNT = "3";
export const DOCUMENT_TYPE_DNI = "DNI";
export const DOCUMENT_TYPE_RUC = "RUC";
export const DOCUMENT_TYPE_CEE = "CEE";
export const DOCUMENT_PAYMENT_TYPE_QUOTATION = "QUOTATION";
export const PAYMENT_TYPE_CASH = "CASH";
export const PAYMENT_TYPE_CREDIT = "CREDIT";
export const VIEW_PAYMENT_CONFIRMATION = "CONFIRMATION";
export const VIEW_PAYMENT_ORDER = "ORDER";
export const VAT_TYPE_TAXED = "taxed";
export const VAT_TYPE_EXEMPTED = "exempted";
export const VAT_TYPE_UNAFFECTED = "unaffected";
export const VAT_TYPE_FREE = "free";
export const DEFAULT_VAT = 0.18;
export const PAYMENT_METHOD_CASH = "cash";
export const ALL = 0;
