import React, { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSessionStore } from '@/store/session.store.ts';
import { useCashierStore } from '@/store/cashier.store';

interface PrivateRouteProps {
  children: ReactNode;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { authToken } = useSessionStore();

  const cashierId = useCashierStore(state => state.cashierId)
  const cashier = useCashierStore(state => state.cashier)


  const navigate = useNavigate();

  useEffect(() => {
    if (!authToken) {
      navigate("/login");
      return;
    }

    if (!cashierId) {
      navigate("/stores");
      return;
    }

    if (authToken && cashierId && !cashier) {
      return;
    }

    if (authToken && cashierId && cashier && cashier.status === "opening") {
      navigate(`/cashiers/${cashier.id}/open`);
      return;
    }

  }, [authToken, cashier, cashierId, navigate]);

  return <>{children}</>;
};