import { TCashierSummary } from "@/models/cashier-summary.model";
import { IExpense } from "@/pages/[others]/pages/close-cashier/models/cashier-expense.model";
import { IRevenue } from "@/pages/[others]/pages/close-cashier/models/cashier-revenue.model";
import { ICashier, IWorkShift } from "@/pages/home/pages/stores/models";
import Cookies from 'js-cookie';
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { IdbStorage } from ".";
import createDeepMerge from '@fastify/deepmerge';

const deepMerge = createDeepMerge({ all: true });

export interface ISalePaymentDetails {
  method: string;
  total: number;
  type: string;
}

export interface ISaleDocumentDetails {
  document: string;
  total: number;
  type: string
}

export interface CashierState {
  cashierId: number | undefined;
  setCashierId: (cashierId: number | undefined) => void;
  cashier: ICashier | undefined;
  setCashier: (cashier: ICashier | undefined) => void;
  workshift: IWorkShift | undefined;
  setWorkshift: (workshift: IWorkShift | undefined) => void;
  cashierOpeningSummary: TCashierSummary | undefined;
  setCashierOpeningSummary: (cashierOpeningSummary: TCashierSummary | undefined) => void;
  cashierClosingSummary: TCashierSummary | undefined,
  setCashierClosingSummary: (cashierClosingSummary: TCashierSummary | undefined) => void;
  revenues: IRevenue[] | undefined;
  setRevenues: (revenue: IRevenue[] | undefined) => void;
  totalRevenue: number | undefined;
  setTotalRevenue: (totalRevenue: number) => void;
  expenses: IExpense[] | undefined;
  setExpenses: (expense: IExpense[] | undefined) => void;
  totalExpense: number | undefined;
  setTotalExpense: (totalExpense: number) => void;
  salePaymentDetails: ISalePaymentDetails[] | undefined;
  setSalePaymentDetails: (salePaymentDetails: ISalePaymentDetails[]) => void;
  finalCashBalance: number | undefined;
  setFinalCashBalance: (finalCashBalance: number | undefined) => void;
  finalVirtualBalance: number | undefined;
  setFinalVirtualBalance: (finalVirtualBalance: number | undefined) => void;
  saleDocumentDetails: ISaleDocumentDetails[] | undefined;
  setSaleDocumentDetails: (saleDocumentDetails: ISaleDocumentDetails[]) => void;
}

export const useCashierStore = create<CashierState>()(devtools(
  persist((set) => ({
    cashierId: parseInt(Cookies.get('cashierId') ?? "0") || undefined,
    setCashierId: (cashierId: number | undefined) => {
      if (cashierId) {
        Cookies.set('cashierId', cashierId.toString(), { expires: 3 });
      } else {
        Cookies.remove('cashierId');
      }
      set({ cashierId });
    },
    cashier: undefined,
    setCashier: (cashier: ICashier | undefined) => set({ cashier }),
    workshift: undefined,
    setWorkshift: (workshift: IWorkShift | undefined) => set({ workshift }),
    cashierOpeningSummary: undefined,
    setCashierOpeningSummary: (cashierOpeningSummary: TCashierSummary | undefined) => set({ cashierOpeningSummary }),
    cashierClosingSummary: undefined,
    setCashierClosingSummary: (cashierClosingSummary: TCashierSummary | undefined) => set({ cashierClosingSummary }),
    revenues: undefined,
    setRevenues: (revenues: IRevenue[] | undefined) => set({ revenues }),
    totalRevenue: 0,
    setTotalRevenue: (totalRevenue: number) => set({ totalRevenue }),
    expenses: undefined,
    setExpenses: (expenses: IExpense[] | undefined) => set({ expenses }),
    totalExpense: 0,
    setTotalExpense: (totalExpense: number) => set({ totalExpense }),
    salePaymentDetails: undefined,
    setSalePaymentDetails: (salePaymentDetails: ISalePaymentDetails[]) => set({ salePaymentDetails }),
    finalCashBalance: 0,
    setFinalCashBalance: (finalCashBalance: number | undefined) => set({ finalCashBalance }),
    finalVirtualBalance: 0,
    setFinalVirtualBalance: (finalVirtualBalance: number | undefined) => set({ finalVirtualBalance }),
    saleDocumentDetails: undefined,
    setSaleDocumentDetails: (saleDocumentDetails: ISaleDocumentDetails[]) => set({ saleDocumentDetails })
  }),
    {
      name: 'cashier-store',
      partialize: (state) => {
        return {
          cashier: state.cashier,
          workshift: state.workshift,
          cashierOpeningSummary: state.cashierOpeningSummary,
          revenue: state.revenues,
          expense: state.expenses,
          salePaymentDetails: state.salePaymentDetails,
          saleDocumentDetails: state.saleDocumentDetails
        };
      },
      storage: createJSONStorage(() => IdbStorage),
      merge: (persistedState, currentState) => {
        if (!persistedState) return currentState;
        return deepMerge(currentState, persistedState) as never;
      }
    }
  ))
);
