import { useState, forwardRef } from "react";
import { Button, Input, type InputProps } from "@/components";
import { EyeIcon, EyeOffIcon } from "lucide-react";
import { cn } from "@/utilities";

export interface PasswordInputProps extends Omit<InputProps, "type"> {
  className?: string;
}

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  ({ className, ...props }, ref) => {
    const [ showPassword, setShowPassword ] = useState(false);

    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

    return (
      <div className="relative">
        <Input
          type={ showPassword ? "text" : "password" }
          className={ cn("pr-10", className) }
          ref={ ref }
          { ...props }
        />
        <Button
          type="button"
          variant="ghost"
          size="icon"
          className="absolute right-0 top-0 h-full hover:bg-transparent text-muted-foreground"
          onClick={ togglePasswordVisibility }
          aria-label={ showPassword ? "Hide password" : "Show password" }
        >
          { showPassword ? (
            <EyeOffIcon className="h-4 w-4"/>
          ) : (
            <EyeIcon className="h-4 w-4"/>
          ) }
        </Button>
      </div>
    );
  }
);

PasswordInput.displayName = "PasswordInput";